import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
declare var fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelTrackService {

  constructor(
    private facebookPixel: FacebookPixelTrackService,
  ) { }

  // Track ViewContent event
  public trackViewContent(contentIds: string[], contentType: string, content_name: string,
                          currency: string, price: string, content_category: string, 
                          description: string, title: string,link: string, availability: string,  event_id: string) {
    const eventData = {
      content_ids: [contentIds], 
      content_type: contentType,
      content_name: content_name,
      currency: currency,
      price: price, 
      content_category: content_category,
      description: description,  
      title: title,
      link: link, 
      availability: availability,  
      event_id: event_id,
    };

    localStorage.setItem('eventId', eventData.event_id);

    console.log('ViewContent Pixel Data==>', eventData);

    fbq('track', 'ViewContent', eventData);
  }



  // Track AddToCart event
  public trackAddToCart(contentIds: string[], contentType: string, contents: any[],
    currency: string, value: number, event_id: string) {
    const eventData = {
      content_ids: [contentIds],
      content_type: contentType,
      contents: contents,
      currency: currency,
      value: value,
      event_id: event_id,
    };
    console.log('TrackAddToCart Pixel Data==>', eventData);
    localStorage.setItem('eventId', eventData.event_id);
    fbq('track', 'AddToCart', eventData);
  }

  public trackPurchase(contentIds: string[], contentType: string,
    currency: string, value: number,price: string, content_category: string, 
    description: string, title: string, link: string, image_link:string,id:string, event_id: string) {
    const eventData = {
      content_ids: [contentIds],
      content_type: contentType,
      // contents: contents,
      currency: currency,
      value: value,
      price: price, 
      content_category: content_category,
      description: description,  
      title: title,
      link: link, 
      image_link :image_link,
      // availability: availability,
      id:id,
      event_id: event_id,
    };
    console.log('TrackPurchase Pixel Data==>', eventData);
    localStorage.setItem('eventId', eventData.event_id);
    fbq('track', 'Purchase', eventData);
  }
}
